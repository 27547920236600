// extracted by mini-css-extract-plugin
export var active = "W_8";
export var arrow = "W_5";
export var border = "W_dg";
export var button = "W_b1";
export var column = "W_cc";
export var columns = "W_cb";
export var container = "W_b";
export var description = "W_db";
export var dot = "W_dl";
export var footer = "W_cD";
export var footerText = "W_ch";
export var heading = "W_G";
export var icon = "W_bs";
export var includes = "W_dj";
export var info = "W_cQ";
export var left = "W_bS";
export var mobileOption = "W_dk";
export var option = "W_dd";
export var optionText = "W_df";
export var options = "W_dc";
export var padding = "W_dr";
export var plans = "W_dh";
export var popular = "W_dq";
export var price = "W_dm";
export var right = "W_bT";
export var section = "W_d";
export var show = "W_bj";
export var subHeading = "W_H";
export var subText = "W_dp";
export var suffix = "W_dn";
export var title = "W_h";
export var waveOne = "W_q";
export var waveThree = "W_cv";
export var waveTwo = "W_n";
export var waves = "W_ct";