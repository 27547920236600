// extracted by mini-css-extract-plugin
export var bottomContainer = "N_cr";
export var buttons = "N_cs";
export var icon = "N_bs";
export var link = "N_bq";
export var section = "N_d";
export var social = "N_cq";
export var text = "N_cp";
export var title = "N_h";
export var topContainer = "N_cn";
export var videoContainer = "N_cl";
export var videoWrapper = "N_cm";