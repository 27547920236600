// extracted by mini-css-extract-plugin
export var active = "R_8";
export var article = "R_j";
export var articleContainer = "R_c3";
export var articlesContainer = "R_c2";
export var body = "R_c";
export var categories = "R_cW";
export var category = "R_cX";
export var container = "R_b";
export var date = "R_k";
export var featured = "R_c0";
export var featuredContainer = "R_c1";
export var header = "R_cV";
export var hero = "R_f";
export var icon = "R_bs";
export var image = "R_g";
export var inputContainer = "R_cY";
export var search = "R_cj";
export var searchInput = "R_cZ";
export var section = "R_d";
export var title = "R_h";