// extracted by mini-css-extract-plugin
export var buttons = "P_cs";
export var column = "P_cc";
export var columns = "P_cb";
export var container = "P_b";
export var down = "P_cy";
export var download = "P_cx";
export var footer = "P_cD";
export var information = "P_cw";
export var section = "P_d";
export var show = "P_bj";
export var text = "P_cp";
export var title = "P_h";
export var units = "P_cz";
export var up = "P_cC";
export var upload = "P_cB";
export var waveOne = "P_q";
export var waveThree = "P_cv";
export var waveTwo = "P_n";
export var waves = "P_ct";