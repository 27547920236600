// extracted by mini-css-extract-plugin
export var answer = "bB_gm";
export var arrow = "bB_5";
export var body = "bB_c";
export var category = "bB_cX";
export var contactIcons = "bB_gf";
export var current = "bB_fj";
export var fade = "bB_gj";
export var footer = "bB_cD";
export var footerText = "bB_ch";
export var icon = "bB_bs";
export var iconContainer = "bB_b0";
export var issue = "bB_gl";
export var issueContainer = "bB_gh";
export var issueText = "bB_gk";
export var issues = "bB_gg";
export var left = "bB_bS";
export var padding = "bB_dr";
export var right = "bB_bT";
export var section = "bB_d";
export var selectors = "bB_dF";
export var subtitle = "bB_cg";
export var title = "bB_h";