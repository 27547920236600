// extracted by mini-css-extract-plugin
export var body = "bL_c";
export var button = "bL_b1";
export var close = "bL_F";
export var container = "bL_b";
export var content = "bL_bQ";
export var heading = "bL_G";
export var link = "bL_bq";
export var mobileHide = "bL_g3";
export var open = "bL_9";
export var section = "bL_d";
export var show = "bL_bj";
export var title = "bL_h";