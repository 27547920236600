// extracted by mini-css-extract-plugin
export var background = "bK_z";
export var backgroundContainer = "bK_ff";
export var bottomWave = "bK_cf";
export var buttons = "bK_cs";
export var container = "bK_b";
export var content = "bK_bQ";
export var icon = "bK_bs";
export var iconContainer = "bK_b0";
export var middleWave = "bK_cd";
export var negativeTop = "bK_g0";
export var overlap = "bK_g2";
export var section = "bK_d";
export var selected = "bK_bb";
export var tab = "bK_dD";
export var tabText = "bK_dC";
export var title = "bK_h";
export var topWave = "bK_g1";