// extracted by mini-css-extract-plugin
export var container = "bD_b";
export var feature = "bD_gK";
export var featureText = "bD_gL";
export var features = "bD_gJ";
export var footer = "bD_cD";
export var footerText = "bD_ch";
export var icon = "bD_bs";
export var section = "bD_d";
export var text = "bD_cp";
export var title = "bD_h";
export var waveOne = "bD_q";
export var waveThree = "bD_cv";
export var waveTwo = "bD_n";
export var waves = "bD_ct";