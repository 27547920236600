// extracted by mini-css-extract-plugin
export var buttons = "bJ_cs";
export var left = "bJ_bS";
export var media = "bJ_gZ";
export var right = "bJ_bT";
export var section = "bJ_d";
export var selected = "bJ_bb";
export var signature = "bJ_gY";
export var testimonial = "bJ_gX";
export var text = "bJ_cp";
export var textContainer = "bJ_bY";
export var video = "bJ_fS";