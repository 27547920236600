// extracted by mini-css-extract-plugin
export var bottom = "bw_s";
export var contact = "bw_fv";
export var faqCat = "bw_fD";
export var faqCatDescription = "bw_fH";
export var faqCatTitle = "bw_fG";
export var faqCategories = "bw_fC";
export var faqIcon = "bw_fF";
export var footerText = "bw_ch";
export var icon = "bw_bs";
export var iconContainer = "bw_b0";
export var iconText = "bw_fx";
export var immediate = "bw_fw";
export var inputContainer = "bw_cY";
export var linksSubtitle = "bw_fz";
export var linksTitle = "bw_fy";
export var search = "bw_cj";
export var searchInput = "bw_cZ";
export var section = "bw_d";
export var selected = "bw_bb";
export var supportButton = "bw_fJ";
export var supportLinks = "bw_fB";
export var top = "bw_l";
export var wave = "bw_ft";