// extracted by mini-css-extract-plugin
export var bottomWave = "bt_cf";
export var button = "bt_b1";
export var buttonContainer = "bt_cJ";
export var buttonFooter = "bt_fp";
export var container = "bt_b";
export var middleWave = "bt_cd";
export var section = "bt_d";
export var subtitle = "bt_cg";
export var textContainer = "bt_bY";
export var title = "bt_h";