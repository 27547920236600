// extracted by mini-css-extract-plugin
export var active = "bh_8";
export var arrow = "bh_5";
export var button = "bh_b1";
export var buttonMobile = "bh_dW";
export var column = "bh_cc";
export var columns = "bh_cb";
export var container = "bh_b";
export var description = "bh_db";
export var disclaimer = "bh_cR";
export var disclaimerButton = "bh_dR";
export var disclaimerContainer = "bh_dY";
export var disclaimerText = "bh_d1";
export var fadeBottom = "bh_d0";
export var fadeTop = "bh_dZ";
export var featureFooter = "bh_dT";
export var footer = "bh_cD";
export var footerMobile = "bh_dV";
export var heading = "bh_G";
export var highlight = "bh_dS";
export var icon = "bh_bs";
export var iconContainer = "bh_b0";
export var left = "bh_bS";
export var padding = "bh_dr";
export var popular = "bh_dq";
export var prefix = "bh_dQ";
export var recommend = "bh_dN";
export var recommended = "bh_dP";
export var right = "bh_bT";
export var section = "bh_d";
export var show = "bh_bj";
export var subHeading = "bh_H";
export var suffix = "bh_dn";
export var title = "bh_h";
export var waveContainer = "bh_dX";
export var waveOne = "bh_q";
export var waveThree = "bh_cv";
export var waveTwo = "bh_n";
export var waves = "bh_ct";