// extracted by mini-css-extract-plugin
export var caption = "bF_gM";
export var grey = "bF_dx";
export var heading = "bF_G";
export var image = "bF_g";
export var left = "bF_bS";
export var reverse = "bF_dt";
export var right = "bF_bT";
export var section = "bF_d";
export var video = "bF_fS";
export var videoContainer = "bF_cl";
export var videoWrapper = "bF_cm";