// extracted by mini-css-extract-plugin
export var buttons = "bf_cs";
export var container = "bf_b";
export var content = "bf_bQ";
export var icon = "bf_bs";
export var iconContainer = "bf_b0";
export var image = "bf_g";
export var section = "bf_d";
export var selected = "bf_bb";
export var tab = "bf_dD";
export var tabText = "bf_dC";