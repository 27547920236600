// extracted by mini-css-extract-plugin
export var active = "by_8";
export var categories = "by_cW";
export var category = "by_cX";
export var icon = "by_bs";
export var icons = "by_bp";
export var image = "by_g";
export var member = "by_fP";
export var memberTitle = "by_fQ";
export var name = "by_fq";
export var placeholder = "by_6";
export var section = "by_d";
export var show = "by_bj";
export var subtitle = "by_cg";
export var team = "by_fN";