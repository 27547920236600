// extracted by mini-css-extract-plugin
export var active = "bz_8";
export var categories = "bz_cW";
export var category = "bz_cX";
export var description = "bz_db";
export var featured = "bz_c0";
export var icon = "bz_bs";
export var input = "bz_ck";
export var mobileVidContainer = "bz_f9";
export var mobileVideo = "bz_gb";
export var mobileVideoThumbnail = "bz_gc";
export var mobileVideoTitle = "bz_gd";
export var mobileVidsContainer = "bz_f8";
export var moreVideo = "bz_f4";
export var moreVideoContainer = "bz_f3";
export var moreVideoThumbnail = "bz_f5";
export var moreVideoTitle = "bz_f6";
export var moreVideos = "bz_fW";
export var moreVideosHeader = "bz_fV";
export var moreVideosSlider = "bz_f2";
export var moreVideosSliderMobile = "bz_f1";
export var scrollArrow = "bz_fY";
export var scrollButton = "bz_fX";
export var scrollLeft = "bz_fZ";
export var scrollRight = "bz_f0";
export var search = "bz_cj";
export var section = "bz_d";
export var sectionTitle = "bz_fT";
export var selector = "bz_f7";
export var selectors = "bz_dF";
export var video = "bz_fS";
export var videoContainer = "bz_fR";