// extracted by mini-css-extract-plugin
export var appStores = "X_dw";
export var grey = "X_dx";
export var icon = "X_bs";
export var iconGroup = "X_dv";
export var image = "X_g";
export var left = "X_bS";
export var reverse = "X_dt";
export var right = "X_bT";
export var section = "X_d";
export var textSection = "X_ds";