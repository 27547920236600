// extracted by mini-css-extract-plugin
export var body = "bv_c";
export var buttons = "bv_cs";
export var container = "bv_b";
export var icon = "bv_bs";
export var icons = "bv_bp";
export var image = "bv_g";
export var items = "bv_fr";
export var label = "bv_cG";
export var mobileButtons = "bv_fs";
export var name = "bv_fq";
export var section = "bv_d";
export var selected = "bv_bb";
export var show = "bv_bj";
export var title = "bv_h";