// extracted by mini-css-extract-plugin
export var arrow = "bg_5";
export var body = "bg_c";
export var circle = "bg_dL";
export var customer = "bg_dK";
export var feedback = "bg_dJ";
export var feedbacks = "bg_dG";
export var footer = "bg_cD";
export var footerText = "bg_ch";
export var left = "bg_bS";
export var nameLocation = "bg_dM";
export var padding = "bg_dr";
export var right = "bg_bT";
export var section = "bg_d";
export var selected = "bg_bb";
export var selectors = "bg_dF";
export var subtitle = "bg_cg";
export var text = "bg_cp";
export var title = "bg_h";
export var wrapper = "bg_dH";