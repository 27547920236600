// extracted by mini-css-extract-plugin
export var buttons = "bn_cs";
export var container = "bn_b";
export var image = "bn_g";
export var innerText = "bn_fc";
export var logo = "bn_bk";
export var p = "bn_d9";
export var section = "bn_d";
export var short = "bn_fb";
export var tall = "bn_fd";
export var textContainer = "bn_bY";