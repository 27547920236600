// extracted by mini-css-extract-plugin
export var container = "S_b";
export var content = "S_bQ";
export var icon = "S_bs";
export var iconContainer = "S_b0";
export var icons = "S_bp";
export var image = "S_g";
export var label = "S_cG";
export var section = "S_d";
export var text = "S_cp";
export var title = "S_h";