// extracted by mini-css-extract-plugin
export var bottomCircle = "bG_gP";
export var content = "bG_bQ";
export var description = "bG_db";
export var hide = "bG_C";
export var icon = "bG_bs";
export var image = "bG_g";
export var imageContainer = "bG_gT";
export var left = "bG_bS";
export var nextYear = "bG_gS";
export var right = "bG_bT";
export var section = "bG_d";
export var shrink = "bG_gR";
export var title = "bG_h";
export var topCircle = "bG_gN";
export var wave = "bG_ft";
export var waveContainer = "bG_dX";
export var year = "bG_gQ";